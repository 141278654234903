import { DiscountTypeEnum, VatEnum } from '@/utils/enums';
import { translation } from '@/helpers/TranslationHelper';
import { sum } from 'lodash';
import { getSign } from '@/helpers/SignHelper';
import LocalStorage from '@/helpers/LocalStorageHelper';


// manual number rounding
Number.prototype.mtoFixed = function() {
    if(this === +this && this !== (this|0)) {
        var iToString = this + '';
        let formated = parseFloat(iToString.match(/\d+\.\d{2}/)).toString();
        if(isNaN(formated)) {
            return this.toFixed(2)
        } else {
            return formated;
        }
    } else {
        return this.toString();
    }
}

export const getPriceWithDiscount = (price, discountAmount, discountType) => {
    switch (discountType) {
        case DiscountTypeEnum.PERCENTAGE:
            return (price - (price * discountAmount) / 100).toFixed(2);
        case DiscountTypeEnum.FIXED:
            return (price - discountAmount).toFixed(2);

        default:
            return price.toFixed(2);
    }
};

export const getDiscount = (price, discountAmount, discountType = DiscountTypeEnum.PERCENTAGE) => {
    switch (discountType) {
        case DiscountTypeEnum.PERCENTAGE:
            return ((price * discountAmount) / 100).toFixed(2);
        case DiscountTypeEnum.FIXED:
            return discountAmount.toFixed(2);
        default:
            return (0).toFixed(2);
    }
};

export const getMealKitPrice = (mealKit, amount, periodAmount, caloriePrice) => {
    if (!mealKit.isUnique) {
        return (caloriePrice * amount * periodAmount).toFixed(2);
    }

    return (amount * mealKit.price).toFixed(2);
};

export const getPricesArray = (state, calcFunc, field, multiplier) => {
    let priceArray = [];
    state.forEach((element) => {
        priceArray.push(Number(calcFunc(element[field], multiplier)));
    });

    return priceArray;
};

export const getAdditionPrice = (price, periodAmount) => {
    return (periodAmount * price).toFixed(2);
};

export const getAllergenPrice = (percentage, mealKitPrice) => {
    return ((mealKitPrice * percentage) / 100).toFixed(2);
};

export const getTotalMealKitPrice = (mealKit, additions, periodAmount, mealKitPriceWithDiscount) => {
    if (!mealKit.isUnique) {
        let additionsPriceArray = getPricesArray(additions, getAdditionPrice, 'price', periodAmount);

        return (Number(mealKitPriceWithDiscount) + Number(sum(additionsPriceArray))).toFixed(2);
    }

    return mealKitPriceWithDiscount.toFixed(2);
};

export const getPriceForAllAllergens = (allergens, mealKitPrice) => {
    let allergensPriceArray = getPricesArray(allergens, getAllergenPrice, 'percentage', mealKitPrice);

    return Number(sum(allergensPriceArray)).toFixed(2);
};

export const getMealKitAllPrices = (item) => {
    const caloriePrice = item.calorie?.prices.find((elem) => elem.period_id === item.period?.id).price;

    const mealKitPrice = getMealKitPrice(item.mealKit, item.amount, item.period?.amount, caloriePrice);

    const mealKitPriceWithAllergens =
        Number(mealKitPrice) + Number(getPriceForAllAllergens(item.allergens, Number(mealKitPrice)));

    const mealKitPriceWithDiscount = getPriceWithDiscount(
        Number(mealKitPriceWithAllergens),
        item.mealKit.discount?.amount,
        item.mealKit.discount?.type
    );

    const totalPrice = getTotalMealKitPrice(
        item.mealKit,
        item.additions,
        item.period?.amount,
        Number(mealKitPriceWithDiscount)
    );

    const discountAmount = getDiscount(
        mealKitPriceWithAllergens,
        item.mealKit.discount?.amount,
        item.mealKit.discount?.type
    );

    const totalPriceWithoutDiscount = getTotalMealKitPrice(
        item.mealKit,
        item.additions,
        item.period?.amount,
        Number(mealKitPriceWithAllergens)
    );

    return {
        mealKitPrice,
        mealKitPriceWithDiscount,
        totalPrice,
        mealKitPriceWithAllergens,
        discountAmount,
        totalPriceWithoutDiscount,
    };
};

export const calcCommonPrices = (cart, deliveryPrices, vatData, appliedDiscountPoints) => {
    const priceArray = [];
    const discountCodesArray = [];

    const discountWithPoints = appliedDiscountPoints ? appliedDiscountPoints.discountWithPoints : 0;
    let isFreeDelivery = false;

    cart.forEach((element) => {
        priceArray.push(Number(element.totalPrice));
        if (element.appliedDiscountCodes.length) {
            element.appliedDiscountCodes.forEach((discountFromCodeAmount) => {
                if(discountFromCodeAmount.isFreeDelivery === true) {
                    isFreeDelivery = true;
                }

                discountCodesArray.push(Number(discountFromCodeAmount.price));                
            });
        }
    });

    // delivery price
    let deliveryPrice = (0).toFixed(2);
    let uniquePrice = (0).toFixed(2);
    let regularDeliveryPrice = (0).toFixed(2);
    let uniqueDeliveryPrice = (0).toFixed(2);

    const uniqueKitsAmount = cart.filter((cartItem) => cartItem.mealKit.isUnique).length;
    const regularKits = cart.filter((cartItem) => !cartItem.mealKit.isUnique);

    if (deliveryPrices.length && isFreeDelivery === false) {
        if (regularKits.length) {
            const periodAmountArray = regularKits.map((kit) => kit.period.amount);
            const maxPeriodAmount = Math.max(...periodAmountArray);
            regularDeliveryPrice = (
                Number(deliveryPrices.find((price) => !price?.isForUnique).price) * Number(maxPeriodAmount)
            ).toFixed(2);
        }

        if (uniqueKitsAmount) {
            uniquePrice = deliveryPrices.find((price) => price.isForUnique).price.toFixed(2);
        }

        uniqueDeliveryPrice = (Number(uniquePrice) * uniqueKitsAmount).toFixed(2);

        deliveryPrice = (Number(regularDeliveryPrice) + Number(uniqueDeliveryPrice)).toFixed(2);
    }
 
    // discount
    const priceWithoutDiscount = sum(priceArray).toFixed(2);
    const discountFromCodes = sum(discountCodesArray).mtoFixed();
    const discountFromPoints = Number(discountWithPoints).mtoFixed();
    const commonDiscount = (Number(discountFromCodes) + Number(discountFromPoints)).mtoFixed(2);


    // total
    let totalPriceWithOutDelivery = (Number(priceWithoutDiscount) - Number(commonDiscount)).mtoFixed(2);
    let totalPrice = (Number(priceWithoutDiscount) - Number(commonDiscount) + Number(deliveryPrice)).mtoFixed(2);

    totalPriceWithOutDelivery = Number(totalPriceWithOutDelivery) < 0 ? (0).toFixed(2) : totalPriceWithOutDelivery;
    totalPrice = Number(totalPrice) < 0 ? (0).toFixed(2) : totalPrice;

    // vat calculations
    const { kitVatPrice, deliveryVatPrice, vat } = calcVat(vatData, totalPriceWithOutDelivery, deliveryPrice);

    return {
        totalPriceWithOutDelivery,
        priceWithoutDiscount,
        discountFromCodes,
        discountFromPoints,
        totalPrice,
        commonDiscount,
        vat,
        kitVatPrice,
        deliveryVatPrice,
        deliveryPrice,
        uniqueDeliveryPrice,
        regularDeliveryPrice,
    };
};

export const getApplyedDiscountText = (discountCode) => {
    if(discountCode.isFreeDelivery && discountCode.amount === 0) {
        return translation('common.freeDelivery');
    } else {
        return translation('common.discountApplied', {
            amount: discountCode.amount,
            sign: getSign(discountCode.type),
        })
    }
}

export const applyDiscountCodeToCartItem = (discountCode, cart, mode = 'add') => {
    let codesArray = [];
    discountCode.mealKitsIds.forEach((mealKitId) => {
        cart.forEach((cartItem) => {
            let periodCheck = (mealKitId.periods === null ? true : mealKitId.periods.includes(cartItem.period.id));
            if (cartItem.mealKit.id === mealKitId.id && periodCheck) {
                if (
                    mode === 'add' // &&
                    // !(codesArray.includes(discountCode) && discountCode.type === DiscountTypeEnum.FIXED)
                ) {
                    cartItem.appliedDiscountCodes.push({
                        id: discountCode.id,
                        amount: discountCode.amount,
                        type: discountCode.type,
                        isFreeDelivery: discountCode.isFreeDelivery,
                        text: getApplyedDiscountText(discountCode),
                        price: Number(getDiscount(cartItem.totalPrice, discountCode.amount, discountCode.type)),
                    });

                    codesArray = codesArray.concat(discountCode);
                } else {
                    cartItem.appliedDiscountCodes = cartItem.appliedDiscountCodes.filter(
                        (item) => item.id !== discountCode.id
                    );
                }
            }

            LocalStorage.create(`cart-item-${cartItem.id}`, cartItem);
        });
    });
};

export const updateAppliedDiscountCodeInCartItem = (cartItem) => {
    cartItem.appliedDiscountCodes.forEach((element) => {
        element.price = Number(getDiscount(cartItem.totalPrice, element.amount, element.type));
        LocalStorage.create(`cart-item-${cartItem.id}`, cartItem);
    });
};

export const calcVat = (vatData, totalPriceWithOutDelivery, deliveryPrice) => {
    const kitVATPercentage = vatData.find((vatItem) => vatItem.type === VatEnum.KIT)?.percentage;
    const deliveryVATPercentage = vatData.find((vatItem) => vatItem.type === VatEnum.DELIVERY)?.percentage;

    const kitVatPrice = getDiscount(Number(totalPriceWithOutDelivery), kitVATPercentage, DiscountTypeEnum.PERCENTAGE);
    const deliveryVatPrice = getDiscount(Number(deliveryPrice), deliveryVATPercentage, DiscountTypeEnum.PERCENTAGE);

    const vat = (Number(kitVatPrice) + Number(deliveryVatPrice)).mtoFixed(2);

    return {
        kitVatPrice,
        deliveryVatPrice,
        vat,
    };
};

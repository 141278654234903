import { TimerApi } from '@/api';
import { isValid } from '@/helpers/TimerHelper';
import { toast } from '@/helpers/ToastHelper';
import { TYPE } from 'vue-toastification';
import { translation } from '@/helpers/TranslationHelper';

export const timer = {
    namespaced: true,
    state: {
        timer: null,
    },
    getters: {
        timer: (state) => {
            return state.timer;
        },
        isTimerAvailable: (state) => {
            const endTimeArray = state.timer.getAvailableDay.date.split(':');
            const startTimeArray = state.timer.displayedStartTime.split(':');

            const isTimeValid = isValid(
                new Date(),
                startTimeArray[0],
                startTimeArray[1],
                endTimeArray[0],
                endTimeArray[1]
            );
            console.log(isTimeValid);
            return !state.timer.isHidden;
        },
    },
    mutations: {
        setTimer(state, timer) {
            state.timer = timer;
        },
    },
    actions: {
        async getTimer({ commit }) {
            const response = await TimerApi.getTimer();

            commit('setTimer', response.data);
        },

        async updateTimer({ commit, state }) {
            try {
                const response = await TimerApi.update(state.timer.id, state.timer);
                commit('setTimer', response.data);
                toast(translation('common.timerUpdated'), { type: TYPE.SUCCESS });
            } catch (error) {
                Object.entries(error.errors).forEach((element) => {
                    toast(element[1][0]);
                });
            }
        },
    },
};

import { CityApi } from '@/api';
import LocalStorage from '@/helpers/LocalStorageHelper';

export const city = {
    namespaced: true,
    state: {
        cities: [],
        citiesForSelect: [],
        selectedCity: null,
    },
    getters: {
        cities: (state) => {
            return state.cities;
        },
        citiesForSelect: (state) => {
            return state.citiesForSelect;
        },
        selectedCity: (state) => {
            return state.selectedCity;
        },
    },
    mutations: {
        setCities(state, cities) {
            state.cities = cities;
        },
        setCitiesForSelect(state, cities) {
            state.citiesForSelect = cities;
        },
        selectCity(state, city) {
            state.selectedCity = city;
        },
    },
    actions: {
        async getAllCities({ commit, dispatch }) {
            const response = await CityApi.getAll();
            const citiesForSelect = response.data.map((city) => {
                return {
                    label: city.name,
                    value: city.id,
                };
            });

            commit('setCities', response.data);
            commit('setCitiesForSelect', citiesForSelect);
            dispatch('initialCitySelect');
        },

        async setSelectedCity({ commit, state }, selectCity) {
            const selectedCity = state.cities.find((city) => city.id === selectCity.value);
            LocalStorage.create('selectedCity', selectedCity);

            commit('selectCity', selectedCity);
        },

        async initialCitySelect({ commit, state }) {
            if (LocalStorage.show('selectedCity')) {
                commit('selectCity', LocalStorage.show('selectedCity'));
            } else {
                LocalStorage.create('selectedCity', state.cities[0]);
                commit('selectCity', state.cities[0]);
            }
        },
    },
};

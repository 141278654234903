import Api from './api';

export class DiscountCodeApi {
    static checkIsCodeSuitable = async (data) => {
        const response = await Api.axios.post('discount-codes/check', data);
        return response.data;
    };

    static getAll = async () => {
        const response = await Api.axios.get('discount-codes');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('discount-codes', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`discount-codes/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`discount-codes/${id}`);
        return response.data;
    };
}

import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store';
import router from '@/router';
import { AuthApi } from '@/api';
import { errorTransformer, convertObjectKeys } from '@/helpers/ApiHelper';

class Api {
    constructor() {
        this.axios = axios.create({
            baseURL: `${api.env.API_ENDPOINT}/api`,
            headers: {
                'Access-Control-Expose-Headers': 'Authorization',
            },
        });

        this.axios.interceptors.request.use((config) => {
            let token = Cookies.get('Authorization') || null;
            if (token) {
                config.headers.Authorization = token;
            }

            config.headers.locale = localStorage.getItem('lang');

            config.params = convertObjectKeys(config.params);
            if (config.data instanceof FormData) {
                return config;
            }
            config.data = convertObjectKeys(config.data);
            return config;
        });

        this.axios.interceptors.response.use(
            ((response) => {
                if (response?.headers?.authorization) {
                    this.setTokenHeaderAndCookie(response.headers.authorization);
                }
                return Promise.resolve(response);
            }).bind(this),
            (error) => {
                if (error.response.data.message === 'Token has expired') {
                    return AuthApi.refresh().then((response) => {
                        const token = `${response.authorization.type} ${response.authorization.token}`;
                        this.setTokenHeaderAndCookie(token);
                        error.config.headers.authorization = token;
                        return this.axios.request(error.config);
                    });
                }

                if (
                    error.response.data.message === 'Token has expired and can no longer be refreshed' ||
                    error.response.status === 429
                ) {
                    this.setTokenHeaderAndCookie(null);
                    store.dispatch('auth/removeAuthToken');
                    router.push({ name: 'main' });
                }

                errorTransformer(error);
            }
        );
    }

    setTokenHeaderAndCookie(token) {
        if (token) {
            this.axios.defaults.headers.common['Authorization'] = token;
            Cookies.set('Authorization', token);
        } else {
            delete this.axios.defaults.headers.common['Authorization'];
            Cookies.remove('Authorization');
        }
    }
}

export default new Api();

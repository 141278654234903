<template>
    <div v-if="!isCookieConsentVisible" class="cookie-consent">
        <p>
            {{ $t('policies.cookieConsentText') }}
            <router-link class="link" :to="{ name: 'privacyPolicy' }">
                {{ $t('policies.privacyPolicy') }}.
            </router-link>
        </p>
        <CRButton @click="agreeWithCookiePolicy">
            {{ $t('buttons.iAgree') }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import Cookies from 'js-cookie';

    export default {
        name: 'CookieConsent',
        components: { CRButton },
        data() {
            return {
                cookieConsentStatus: Cookies.get('cookie-consent-status'),
            };
        },
        computed: {
            isCookieConsentVisible() {
                return this.cookieConsentStatus || this.cookieConsentStatus === '1';
            },
        },
        methods: {
            agreeWithCookiePolicy() {
                this.cookieConsentStatus = '1';
                Cookies.set('cookie-consent-status', '1');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cookie-consent {
        @include column-align-center;

        width: 100%;

        position: fixed;
        z-index: $index-1000;
        bottom: 0;

        padding: 24px;

        background: $black;

        p {
            color: $white;
            text-align: center;

            margin-bottom: 28px;

            .link {
                text-decoration: underline;
            }
        }
    }

    @include media($xl) {
        .cookie-consent {
            @include row-align-center-justify-center;

            padding: 24px;

            p {
                margin-bottom: 0;
                margin-right: 28px;
            }
        }
    }
</style>

<template>
    <div>
        <router-view />
        <CookieConsent />
    </div>
</template>

<script>
    import CookieConsent from '@/components/Common/CookieConsent/CookieConsent.vue';
    import { mapActions } from 'vuex';

    export default {
        name: 'App',
        components: { CookieConsent },
        provide() {
            return {
                provideApp: this.provided,
            };
        },
        data() {
            return {
                provided: {
                    $screenInnerWidth: 1980,
                    $screenInnerHeight: 1080,
                },
            };
        },
        methods: {
            ...mapActions('city', ['getAllCities']),
            ...mapActions('cart', ['setCart']),

            handleResize() {
                this.provided.$screenInnerWidth = window.innerWidth;
                this.provided.$screenInnerHeight = window.innerHeight;
            },

            scrollFunction() {
                let body = document.body;
                window.addEventListener('scroll', () => {
                    body.classList.add('scroll');
                    clearTimeout(body.scrollTimeout);
                    body.scrollTimeout = setTimeout(() => {
                        body.classList.remove('scroll');
                    }, 500);
                });
            },
        },

        async mounted() {
            await this.getAllCities();
            this.setCart();

            this.handleResize();
            window.addEventListener('resize', this.handleResize);

            this.scrollFunction();
            window.addEventListener('onload', this.scrollFunction);
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.handleResize);
            window.removeEventListener('onload', this.scrollFunction);
        },
    };
</script>

<style lang="scss">
    @import 'assets/sass/style';
</style>

import { checkIfMealKitExist, checkIfCheckOutExist, checkIfBlogArticleExist, checkIfPageExist } from '@/router/Guards/index.guard';

export default [
    {
        path: 'dishes',
        component: () => import('@/pages/Admin/Dishes/Dishes.vue'),
        name: 'adminDishes',
    },
    {
        path: 'menus',
        component: () => import('@/pages/Admin/Menus/Menus.vue'),
        name: 'adminMenu',
    },
    {
        path: 'meal-kits',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'adminMealKits',
        redirect: { name: 'allAdminMealKits' },
        children: [
            {
                path: '',
                name: 'allAdminMealKits',
                component: () => import('@/pages/Admin/MealKits/AllMealKits.vue'),
            },
            {
                path: 'create',
                name: 'createMealKit',
                component: () => import('@/pages/Admin/MealKits/CreateMealKit.vue'),
            },
            {
                path: 'update/:meal_kit_id(\\d+)',
                name: 'updateMealKit',
                component: () => import('@/pages/Admin/MealKits/CreateMealKit.vue'),
                beforeEnter: checkIfMealKitExist,
            },
            {
                path: 'update-individual-order',
                name: 'updateIndividualOrder',
                component: () => import('@/pages/Admin/MealKits/UpdateIndividualOrder.vue'),
            },
        ],
    },
    {
        path: 'discounts',
        component: () => import('@/pages/Admin/Discounts/DiscountsPage.vue'),
        name: 'adminDiscounts',
    },
    {
        path: 'check-out-settings',
        component: () => import('@/pages/Admin/CheckOutSettings/CheckOutSettings.vue'),
        name: 'adminCheckOutSettings',
    },
    {
        path: 'order-management',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'adminOrderManagement',
        redirect: { name: 'allAdminCheckOuts' },
        children: [
            {
                path: '',
                name: 'allAdminCheckOuts',
                component: () => import('@/pages/OrderManagement/AllCheckOutsPage.vue'),
            },
            {
                path: ':check_out_id(\\d+)',
                name: 'adminCheckOut',
                component: () => import('@/pages/OrderManagement/CheckOutPage.vue'),
                beforeEnter: checkIfCheckOutExist,
            },
        ],
    },
    {
        path: 'blog-articles',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'adminBlogArticles',
        redirect: { name: 'allAdminBlogArticles' },
        children: [
            {
                path: '',
                name: 'allAdminBlogArticles',
                component: () => import('@/pages/Admin/BlogArticles/AllBlogArticles.vue'),
            },
            {
                path: 'create',
                name: 'createBlogArticle',
                component: () => import('@/pages/Admin/BlogArticles/CreateBlogArticle.vue'),
            },
            {
                path: 'update/:blog_article_id(\\d+)',
                name: 'updateBlogArticle',
                component: () => import('@/pages/Admin/BlogArticles/CreateBlogArticle.vue'),
                beforeEnter: checkIfBlogArticleExist,
            },
        ],
    },
    {
        path: 'other-settings',
        name: 'otherSettings',
        component: () => import('@/pages/Admin/OtherSettings/OtherSettings.vue'),
    },
    {
        path: 'admins-profile',
        name: 'adminsProfile',
        component: () => import('@/pages/User/PersonalSettings/PersonalSettings.vue'),
    },
    {
        path: 'faq',
        component: () => import('@/pages/Admin/FAQ/AllFaq'),
        name: 'adminFAQ',
    },
    {
        path: 'kit-order-settings',
        component: () => import('@/pages/Admin/KitOrderSettings/KitOrderSettings'),
        name: 'adminKitOrderSettings',
    },
    {
        path: 'pages',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'allAdminPages',
        redirect: { name: 'adminPages' },
        children: [
            {
                path: '',
                component: () => import('@/pages/Admin/Pages/AllPages.vue'),
                name: 'adminPages',
            },
            {
                path: 'create',
                name: 'createPage',
                component: () => import('@/pages/Admin/Pages/CreatePage.vue'),
            },
            {
                path: 'update/:page_id(\\d+)',
                name: 'updatePage',
                component: () => import('@/pages/Admin/Pages/CreatePage.vue'),
                beforeEnter: checkIfPageExist,
            },
        ]
    },
];
